<template>
  <div class="home">
    <div
      class="backg"
      v-bind:style="{
        backgroundImage: 'url(' + bg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
    >
      <div class="home_top">
        <div class="logo_left">
          <img src="../assets/img/logobg.png" class="logo_left_tu" alt="" />
        </div>
        <div class="logo_right">
          <div class="right_title">欢迎登录</div>
          <div class="cont">
            <div class="tabs">
              <div class="tabs_1" v-show="phone" @click="mimas">
                账号密码登录
              </div>
              <div class="tabs_1 tabs_2" v-show="mima">
                账号密码登录
                <div class="lans"></div>
              </div>
              <div class="tabs_1" v-show="mima" @click="phones">
                手机短信登录
              </div>
              <div class="tabs_1 tabs_2" v-show="phone">
                手机短信登录
                <div class="lans"></div>
              </div>
            </div>
            <div class="heng1"></div>
            <div v-show="mima">
              <div class="name_he">
                <img src="../assets/img/name.png" class="name_tu" alt="" />
                <div class="name_zi">账号</div>
              </div>
              <input
                class="input_hao"
                v-model="phoneValue"
                maxlength="11"
                type="text"
                placeholder="请输入手机号"
              />
              <div class="name_he">
                <img src="../assets/img/key.png" class="name_tu" alt="" />
                <div class="name_zi">密码</div>
              </div>
              <div class="pwd_inp">
                <input
                  class="input_hao"
                  v-model="pwdValue"
                  :type="password"
                  @input="change"
                  placeholder="请输入密码"
                />
                <img
                  :src="eye1"
                  v-show="bi"
                  class="eye_tu"
                  @click="eye1s"
                  alt=""
                />
                <img
                  :src="eye2"
                  v-show="kai"
                  class="eye_tu"
                  @click="eye2s"
                  alt=""
                />
              </div>
              <div class="pwd_inp3">
                <div style="display: flex; align-item: center">
                  还没有汇成国际账号，立即免费
                  <router-link to="/register">注册</router-link>
                </div>
                <router-link to="/forget" class="wangji">
                  忘记密码
                </router-link>
              </div>
              <div class="pwd_inp2">
                <img
                  src="../assets/img/wrong.png"
                  v-show="wrong"
                  class="wrong_tu"
                  alt=""
                />
                <div class="wrong" v-show="wrong">密码错误</div>
              </div>
              <el-button
                type="primary"
                class="btn"
                @click="loginPwd"
                :disabled="disabled"
                :style="{ background: background }"
                round
                >登录</el-button
              >
              <div class="inp_check">
                <input type="checkbox" v-model="checked" class="check" />
                <div class="xieyi">
                  阅读并已同意
                  <router-link to="/userAgreements">《用户协议》</router-link>
                  及
                  <router-link to="/privacyAgreement">《隐私协议》</router-link>
                </div>
              </div>
            </div>
            <div v-show="phone">
              <div class="name_he">
                <img src="../assets/img/name.png" class="name_tu" alt="" />
                <div class="name_zi">账号</div>
              </div>
              <div class="pwd_inp">
                <input
                  class="input_hao"
                  v-model="phoneValue"
                  maxlength="11"
                  type="text"
                  placeholder="请输入手机号"
                />
                <img
                  src="../assets/img/close.png"
                  class="close_tu"
                  @click="close"
                  alt=""
                />
              </div>
              <div class="name_he">
                <!-- <img src="../assets/img/key.png" class="name_tu" alt=""> -->
                <div class="name_zi"></div>
              </div>
              <div class="pwd_inp">
                <input
                  class="input_hao2"
                  v-model="codeValue"
                  type="text"
                  @input="changecode"
                  placeholder="请输入收到的验证码"
                />
                <button
                  type="info"
                  v-show="show"
                  @click="getCode"
                  class="getMa"
                >
                  获取验证码
                </button>
                <button type="info" v-show="!show" class="getMa">
                  重新发送({{ count }}s)
                </button>
              </div>
              <div class="pwd_inp3">
                <div style="display: flex; align-item: center">
                  还没有汇成国际账号，立即免费
                  <router-link to="/register">注册</router-link>
                </div>
                <router-link to="/forget" class="wangji">
                  忘记密码
                </router-link>
              </div>
              <el-button
                type="primary"
                class="btn"
                :disabled="disabled2"
                @click="loginPhone"
                :style="{ background: background2 }"
                round
                >登录</el-button
              >
              <div class="inp_check">
                <input type="checkbox" v-model="checked" class="check" />
                <div class="xieyi">
                  阅读并已同意 <router-link to="/userAgreements">《用户协议》</router-link> 及
                  <router-link to="/privacyAgreement">《隐私协议》</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginPwd, getVerifyCodeLogin, loginPhone } from "@network/login";
export default {
  name: "Login",
  data() {
    return {
      bg: require("@/assets/img/logbg.png"),
      mima: true,
      phone: false,
      phoneValue: "",
      pwdValue: "",
      codeValue: "",
      password: "password",
      eye1: require("@/assets/img/eye1.png"),
      eye2: require("@/assets/img/eye2.png"),
      bi: true,
      kai: false,
      background: "#E0E0E0",
      background2: "#E0E0E0",
      wrong: false,
      checked: false,
      disabled: true,
      disabled2: true,
      show: true,
      count: "",
      timer: null,
      reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      exp: "",
      code: "",
      verify: "",
    };
  },
  components: {},

  mounted() {
    window.addEventListener("keydown", this.keyDown);
  },

  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },

  methods: {
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        if (this.phone) {
          this.loginPhone();
        } else {
          this.loginPwd();
        }
      }
    },

    // 登陆 手机
    loginPhone() {
      if (this.codeValue == "") {
        this.$message({
          message: "验证码不能为空",
          type: "error",
          offset: 300,
        });
        return false;
      } else if (!this.checked) {
        // 弹窗
        this.$message({
          message: "请认真阅读并同意所有协议",
          type: "error",
          offset: 300,
        });
        return false;
      }
      loginPhone(this.phoneValue, this.codeValue, this.exp, this.verify).then(
        (res) => {
          console.log(res);
          if (res.code != 0) {
            this.$message({
              message: res.msg,
              type: "error",
              offset: 300,
            });
            return false;
          }
          this.$message({
            message: res.msg,
            type: "success",
            offset: 300,
          });
          this.$storage.set("token", res.data.token);
          localStorage.setItem("username", this.phoneValue);
          this.$router.replace("/vipCenter/profile");
        }
      );
    },
    // 获取验证码
    getCode() {
      if (this.phoneValue == "") {
        this.$message({
          message: "请输入手机号",
          type: "error",
          offset: 300,
        });
        return false;
      }
      if (!this.reg.test(this.phoneValue)) {
        this.$message({
          message: "手机号格式不正确",
          type: "error",
          offset: 300,
        });
        return false;
      }
      const TIME_COUNT = 180;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.codes();
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    codes() {
      getVerifyCodeLogin(this.phoneValue).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
            offset: 300,
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error",
            offset: 300,
          });
        }

        if (res.code !== 0) {
          return false;
        } else {
          this.exp = res.data.exp;
          this.verify = res.data.verify;
          this.code = res.data.code;
        }
      });
    },

    // 登陆 账号
    loginPwd() {
      if (this.phoneValue.trim() == "") {
        this.$message({
          message: "请输入手机号",
          type: "error",
          offset: 300,
        });

        return false;
      } else if (!this.reg.test(this.phoneValue)) {
        // 弹窗
        this.$message({
          message: "请输入正确手机号",
          type: "error",
          offset: 300,
        });
        return false;
      } else if (this.pwdValue.trim() == "") {
        // 弹窗
        this.$message({
          message: "请输入密码",
          type: "error",
          offset: 300,
        });
        return false;
      } else if (!this.checked) {
        // 弹窗
        this.$message.error({
          message: "请认真阅读并同意所有协议",

          offset: 300,
        });
        return false;
      }
      // console.log(this.phoneValue)
      // console.log(this.pwdValue)
      // console.log(this.checked)
      loginPwd(this.phoneValue, this.pwdValue).then((res) => {
        // console.log(res);
        if (res.code !== 0) {
          // 弹窗
          this.$message({
            message: res.msg,
            type: "error",
            offset: 300,
          });
          return false;
        } else {
          this.$storage.set("token", res.data.token);
          localStorage.setItem("username", this.phoneValue);
          this.$router.replace("/vipCenter/profile");
        }
      });
    },
    nav() {
      this.$router.push("/forget");
    },
    close() {
      this.phoneValue = "";
    },

    change() {
      if (this.pwdValue == "") {
        this.background = "#E0E0E0";
        this.disabled = true;
      } else {
        this.background = "#1578FF";
        this.disabled = false;
      }
    },
    changecode() {
      if (this.codeValue == "") {
        this.background2 = "#E0E0E0";
        this.disabled2 = true;
      } else {
        this.background2 = "#1578FF";
        this.disabled2 = false;
      }
    },
    mimas() {
      this.mima = true;
      this.phone = false;
    },
    phones() {
      this.mima = false;
      this.phone = true;
    },
    eye1s() {
      this.password = "text";
      this.bi = false;
      this.kai = true;
    },
    eye2s() {
      this.password = "password";
      this.kai = false;
      this.bi = true;
    },
  },
};
</script>
<style lang='less' scoped>
@import "../assets/css/login.less";
</style>
